<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-spin :spinning="loading">
          <div>
            <a-tree
              :key="orgTreeKey"
              v-if="orgTree.length"
              :defaultExpandAll="defaultExpandAll"
              :showLine="true"
              :treeData="orgTree"
              :selectedKeys="selectedKeys"
              @select="selectMisson"
            >
              <template slot="title" slot-scope="text">
                <span> {{ text.titleName }}</span>
                <span>
                  (完成进度：<a-progress
                    :strokeColor="{'0%': '#108ee9','100%': '#87d068' }"
                    :percent="text.progress"
                    style="width: 100px"
                  /> 任务状态：{{ text.statusStr }})
                </span>
              </template>
            </a-tree>
          </div>
        </a-spin>
        <a-divider>任务信息</a-divider>
        <a-spin :spinning="loadingMisson">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="任务名称">
                <div style="width: 500px;">
                  {{ missionData.title }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="备注信息">
                <div style="width: 500px;">
                  {{ missionData.desc }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="计划时间">
                <div style="width: 500px;">
                  {{ missionData.planStartTime }} -- {{ missionData.planEndTime }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="创建人">
                <div style="width: 500px;">
                  {{ missionData.creatorName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="创建时间">
                <div style="width: 500px;">
                  {{ missionData.createTime }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="任务执行人">
                <div style="width: 500px;">
                  <span v-if="userMission">{{ userMission.workerName }}</span>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="任务派发人">
                <div style="width: 500px;">
                  <span v-if="userMission">{{ userMission.assignerName }}</span>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="任务状态">
                <div style="width: 500px;">
                  <a-tag color="#595959" v-if="missionData.status === 0">{{ missionData.statusStr }}</a-tag>
                  <a-tag color="#108ee9" v-else-if="missionData.status === 10">{{ missionData.statusStr }}</a-tag>
                  <a-tag color="#2db7f5" v-else-if="missionData.status === 20">{{ missionData.statusStr }}</a-tag>
                  <a-tag color="#87d068" v-else-if="missionData.status === 30">{{ missionData.statusStr }}</a-tag>
                  <a-tag color="#87d068" v-else>{{ missionData.statusStr }}</a-tag>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="任务优先级">
                <div style="width: 500px;">
                  <a-tag color="lime" v-if="missionData.priorityLevel === 1">{{ missionData.priorityLevelStr }}</a-tag>
                  <a-tag color="cyan" v-else-if="missionData.priorityLevel === 2">{{ missionData.priorityLevelStr }}</a-tag>
                  <a-tag color="orange" v-else-if="missionData.priorityLevel === 3">{{ missionData.priorityLevelStr }}</a-tag>
                  <a-tag color="red" v-else>{{ missionData.priorityLevelStr }}</a-tag>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="完成进度">
                <div style="width: 500px;">
                  <a-progress
                    title="任务进度"
                    type="circle"
                    :strokeColor="{'0%': '#108ee9','100%': '#87d068' }"
                    :percent="missionData.progress"
                  />
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="执行开始时间">
                <div style="width: 500px;">
                  {{ missionData.startTime }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="执行完毕时间">
                <div style="width: 500px;">
                  {{ missionData.finishTime }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
        <a-divider>执行信息</a-divider>
        <a-spin :spinning="loadingMissonExec">
          <a-list size="large">
            <a-list-item :key="index" v-for="(item, index) in execList">
              <a-list-item-meta :description="item.title">
                <a slot="title">
                  {{ item.workerName }}({{ item.workerTelephone }})
                </a>
                <a slot="avatar">
                  <a-avatar :src="item.reporterHeadPic" v-if="item.workerHeadPic !== ''"></a-avatar>
                  <a-avatar>U</a-avatar>
                </a>
              </a-list-item-meta>
              <div class="list-content">
                <div class="list-content-item">
                  {{ item.createTime }}
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-spin>
      </div>
      <div>
        <p style="cursor: pointer; text-align: center;" v-if="moreData" @click="moreDataFun">点击加载更多...</p>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { missionDetails, missionExecDetails, missionExecList } from '@/api/mission'
export default {
  name: 'SeeMission',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      labelCol: {
        lg: { span: 4 },
        sm: { span: 8 }
      },
      wrapperCol: {
        lg: { span: 20 },
        sm: { span: 17 }
      },
      loading: false,
      loadingMisson: false,
      loadingMissonExec: false,
      visible: false,
      current: 0,
      keyId: 0,
      missionData: {},
      orgTree: [],
      defaultExpandAll: false,
      selectedKeys: [],
      selectedKeysTager: [],
      userMission: {},
      execList: [],
      execParams: {
        missionKeyId: 0,
        originalKeyId: 0,
        key: '',
        pageNo: 1,
        pageSize: 10
      },
      moreData: false,
      orgTreeKey: 0
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    refreshData () {
      this.$emit('refreshData', 1)
    },
    ininData (keyId, originalKeyId) {
      this.execParams.missionKeyId = keyId
      this.execParams.originalKeyId = originalKeyId
      this.execParams.pageNo = 1
      this.execParams.key = ''
      this.execList = []
      this.missionExecDetailsApi(keyId)
      this.missionExecListApi()
    },
    missionExecDetailsApi (keyId) {
      const _this = this
      _this.keyId = keyId
      _this.seeInfoVisible = true
      _this.loading = true
      _this.loadingMisson = true
      missionExecDetails({ missionKeyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          _this.orgTree = res.result.mission
          _this.selectedKeys = res.result.selectedKeys
          _this.selectedKeysTager = res.result.selectedKeys
          _this.missionData = res.result.missionInfo
          _this.userMission = res.result.userMission
          _this.defaultExpandAll = true
          _this.orgTreeKey = res.timestamp
          console.log(_this.orgTree)
        } else {
          _this.$message.error('系统错误')
        }
        _this.loading = false
        _this.loadingMisson = false
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
        _this.loadingMisson = false
      })
    },
    missionExecListApi () {
      const _this = this
      _this.loadingMissonExec = true
      missionExecList(_this.execParams).then((res) => {
        if (res.errorCode === 0) {
          _this.execList = _this.execList.concat(res.result.data)
          if (res.result.pageNo < res.result.totalPage) {
            _this.moreData = true
          } else {
            _this.moreData = false
          }
        } else {
          _this.$message.error('系统错误')
        }
        _this.loadingMissonExec = false
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loadingMissonExec = false
      })
    },
    missionDetailsApi (keyId, key) {
      const _this = this
      _this.loadingMisson = true
      missionDetails({ missionKeyId: keyId, key: key }).then((res) => {
        if (res.errorCode === 0) {
          _this.missionData = res.result.missionInfo
          _this.userMission = res.result.userMission
        } else {
          _this.$message.error('系统错误')
        }
        _this.loadingMisson = false
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loadingMisson = false
      })
    },
    moreDataFun () {
      this.execParams.pageNo += 1
      this.missionExecListApi()
    },
    selectMisson (selectedKeys, e) {
      if (e.selected) {
        this.selectedKeys = selectedKeys
        this.selectedKeysTager = selectedKeys
        this.execParams.missionKeyId = 0
        this.execParams.originalKeyId = 0
        this.execParams.pageNo = 1
        this.execParams.key = this.selectedKeys[0]
        this.execList = []
        this.missionExecListApi()
        this.missionDetailsApi(0, this.execParams.key)
      } else {
        this.selectedKeys = this.selectedKeysTager
      }
      console.log('selectMisson', selectedKeys, e)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
