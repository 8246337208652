import request from '@/utils/request'
const baseURL = '/baseapi'
const MissionApi = {
  MissionList: '/admin/mission/list',
  MissionCondition: '/admin/mission/condition',
  SaveMission: '/admin/mission/saveMission',
  DelMission: '/admin/mission/delMission',
  CancelMission: '/admin/mission/cancelMission',
  RecoverMission: '/admin/mission/recoverMission',
  MissionInfo: '/admin/mission/missionInfo',
  DeployMission: '/admin/mission/deployMission',
  MissionExecDetails: '/admin/mission/missionExecDetails',
  UserMissionList: '/admin/mission/userMissionList',
  RenewProgress: '/admin/mission/renewProgress',
  MissionExecList: '/admin/mission/missionExecList',
  MissionDetails: '/admin/mission/missionDetails',
  MyMissionList: '/admin/mission/myMissionList'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function missionList (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.MissionList,
    method: 'post',
    data: parameter
  })
}

export function missionCondition () {
  return request({
    baseURL: baseURL,
    url: MissionApi.MissionCondition,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function saveMission (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.SaveMission,
    method: 'post',
    data: parameter
  })
}

export function delMission (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.DelMission,
    method: 'post',
    data: parameter
  })
}

export function cancelMission (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.CancelMission,
    method: 'post',
    data: parameter
  })
}

export function recoverMission (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.RecoverMission,
    method: 'post',
    data: parameter
  })
}

export function missionInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.MissionInfo,
    method: 'post',
    data: parameter
  })
}

export function deployMission (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.DeployMission,
    method: 'post',
    data: parameter
  })
}

export function missionExecDetails (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.MissionExecDetails,
    method: 'post',
    data: parameter
  })
}

export function userMissionList (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.UserMissionList,
    method: 'post',
    data: parameter
  })
}

export function renewProgress (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.RenewProgress,
    method: 'post',
    data: parameter
  })
}

export function missionExecList (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.MissionExecList,
    method: 'post',
    data: parameter
  })
}

export function missionDetails (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.MissionDetails,
    method: 'post',
    data: parameter
  })
}

export function myMissionList (parameter) {
  return request({
    baseURL: baseURL,
    url: MissionApi.MyMissionList,
    method: 'post',
    data: parameter
  })
}
