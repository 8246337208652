<template>
  <page-header-wrapper>
    <template v-slot:content>
      <div class="page-header-content">
        <div class="avatar">
          <a-avatar size="large" :src="currentUser.avatar" />
        </div>
        <div class="content">
          <div class="content-title">
            {{ timeFix }}，{{ user.name }}<span class="welcome-text">{{ welcome }}</span>
          </div>
          <div>{{ user.roleName }}({{ user.applyName }})</div>
        </div>
      </div>
    </template>
    <template v-slot:extraContent>
      <div class="extra-content">
        <div class="stat-item">
          <a-statistic title="房屋数" :value="statisticsInfo.houseCount" />
        </div>
        <div class="stat-item">
          <a-statistic title="住户数" :value="statisticsInfo.residentCount" />
        </div>
        <div class="stat-item">
          <a-statistic title="用户数" :value="statisticsInfo.userInfoCount" />
        </div>
      </div>
    </template>
    <div>
      <a-row :gutter="24">
        <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :loading="loading" title="最新公告" :bordered="false" style="margin-bottom: 24px;">
            <a slot="extra" @click="gotoUserCenter">更多</a>
            <a-list :locale="locale">
              <a-list-item :key="index" v-for="(item, index) in noticeData">
                <a-list-item-meta :description="item.contentStr">
                  <a slot="title" @click="gotoNotice(item)">
                    {{ item.title }}
                  </a>
                </a-list-item-meta>
                <div class="list-content" style="margin-left: 20px;">
                  <span style="width: 60px;text-align: left;">类型</span>
                  <p style="width: 60px;text-align: left;">{{ item.noticeTypeName }}</p>
                </div>
                <div class="list-content" style="margin-left: 20px;">
                  <span>发送时间</span>
                  <p>{{ item.publishTime }}</p>
                </div>
                <div slot="actions">
                  <a @click="gotoNotice(item)">查看</a>
                </div>
              </a-list-item>
            </a-list>
          </a-card>
        </a-col>
        <a-col
          style="padding: 0 12px"
          :xl="8"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <a-card :loading="missionLoading" title="最新任务" :bordered="false">
            <a slot="extra" @click="gotoMission">更多</a>
            <a-list :locale="localemission">
              <a-list-item :key="index" v-for="(item, index) in missionList">
                <a-list-item-meta :description="item.issueTime">
                  <a slot="title" @click="showMissionInfo(item)">
                    {{ item.title }}
                  </a>
                </a-list-item-meta>
                <div slot="actions">
                  <a @click="showMissionInfo(item)">查看</a>
                </div>
              </a-list-item>
            </a-list>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-drawer
        :width="800"
        placement="right"
        @close="closeModel"
        :closable="true"
        :destroyOnClose="true"
        :visible="visible"
        :drawer-style="{ position: 'absolute' }"
      >
        <div style="margin-top: 20px; width: 750px;">
          <h2 style="text-align: center;">{{ noticeInfo.title }}</h2>
          <p v-html="noticeInfo.content"></p>
          <p :key="index" v-for="(item, index) in noticeInfo.imageListData">
            <img
              style="max-width: 100%"
              :src="item.url"
            />
          </p>
        </div>
        <div style="text-align:center;margin-top: 20px;">
          <a-button @click="closeModel">关闭</a-button>
        </div>
      </a-drawer>
    </div>
    <see-mission ref="seemission" @refreshData="refreshData"></see-mission>
  </page-header-wrapper>
</template>

<script>
import { timeFix } from '@/utils/util'
import { mapState } from 'vuex'
import { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import { Radar } from '@/components'
import { overstatistics } from '@/api/overview'
import { myNotice, setIsReaded } from '@/api/notice'
import { myMissionList } from '@/api/mission'
import SeeMission from '@/views/mission/components/SeeMission'

export default {
  name: 'Workplace',
  components: {
    PageHeaderWrapper,
    Radar,
    SeeMission
  },
  data () {
    return {
      timeFix: timeFix(),
      avatar: '',
      user: {},

      projects: [],
      loading: true,
      radarLoading: true,
      activities: [],
      teams: [],
      scale: [
        {
          dataKey: 'score',
          min: 0,
          max: 80
        }
      ],
      radarData: [],
      statisticsInfo: {
        'houseCount': 0,
        'residentCount': 0,
        'userInfoCount': 0
      },
      noticeData: [],
      noticeInfo: {},
      locale: { emptyText: '暂无消息' },
      localemission: { emptyText: '暂无任务' },
      visible: false,
      queryParam: {
        keyId: 0,
        title: '',
        status: -1,
        priorityLevel: 0,
        pageNo: 1,
        pageSize: 10,
        startDate: '',
        endDate: ''
      },
      missionList: [],
      missionLoading: true
    }
  },
  computed: {
    ...mapState({
      nickname: state => state.user.nickname,
      welcome: state => state.user.welcome
    }),
    currentUser () {
      return {
        name: this.userInfo.nickname,
        avatar: this.userInfo.avatar
      }
    },
    userInfo () {
      return this.$store.getters.userInfo
    }
  },
  created () {
    this.user = this.userInfo
    console.log('user', this.user)
    this.avatar = this.userInfo.avatar
    this.myNoticeApi()
    this.myMissionListApi()
    this.overstatisticsApi()
  },
  mounted () {
  },
  methods: {
    overstatisticsApi () {
      const _this = this
      overstatistics().then((res) => {
        if (res.errorCode === 0) {
          _this.statisticsInfo = res.result
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    myNoticeApi () {
      const _this = this
      _this.loading = true
      myNotice({ pageNo: 1, pageSize: 10 }).then((res) => {
        if (res.errorCode === 0) {
          _this.noticeData = res.result.data
        } else {
          _this.$message.error('系统错误')
        }
        _this.loading = false
      }).catch((err) => {
        console.log(err)
        _this.loading = false
      })
    },
    myMissionListApi () {
      const _this = this
      _this.missionLoading = true
      myMissionList(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          _this.missionList = res.result.data
        } else {
          _this.$message.error('系统错误')
        }
        _this.missionLoading = false
      }).catch((err) => {
        console.log(err)
        _this.missionLoading = false
      })
    },
    gotoNotice (data) {
      console.log(data)
      this.noticeInfo = data
      this.setIsReadedApi(data.id)
      this.visible = true
    },
    setIsReadedApi (noticeId) {
      setIsReaded({ noticeId: noticeId }).then((res) => {
        console.log(res)
      }).catch((err) => {
        console.log(err)
      })
    },
    closeModel () {
      this.visible = false
    },
    gotoUserCenter () {
      this.$router.push({ path: '/admincenter/notification' })
    },
    gotoMission () {
      this.$router.push({ path: '/mission/user-mission-list' })
    },
    refreshData (e) {
      console.log(e)
    },
    showMissionInfo (data) {
      this.$refs.seemission.ininData(data.keyId, data.originalKeyId)
    }
  }
}
</script>

<style lang="less" scoped>
@import './Workplace.less';

.project-list {
  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .datetime {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }
}

.item-group {
  padding: 20px 0 8px 24px;
  font-size: 0;

  a {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    margin-bottom: 13px;
    width: 25%;
  }
}

.members {
  a {
    display: block;
    margin: 12px 0;
    line-height: 24px;
    height: 24px;

    .member {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 24px;
      max-width: 100px;
      vertical-align: top;
      margin-left: 12px;
      transition: all 0.3s;
      display: inline-block;
    }

    &:hover {
      span {
        color: #1890ff;
      }
    }
  }
}

.mobile {
  .project-list {
    .project-card-grid {
      width: 100%;
    }
  }

  .more-info {
    border: 0;
    padding-top: 16px;
    margin: 16px 0 16px;
  }

  .headerContent .title .welcome-text {
    display: none;
  }
}
</style>
