import request from '@/utils/request'
const baseURL = '/baseapi'
const OverviewApi = {
  Statistics: '/admin/overview/statistics'
}

export function overstatistics () {
  return request({
    baseURL: baseURL,
    url: OverviewApi.Statistics,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
